<template>
      <div>
        <v-tabs v-model="selectedTabs" center-active show-arrows height="60">
          <v-tab v-for="tab in tabs" @click="updateRoute(tab.route)" :key="tab.name">
            <v-icon left>{{tab.icon}}</v-icon>{{tab.name}}
          </v-tab>
          <!-- 契約情報 -->
          <v-tab-item>
            <v-container>
              <v-expansion-panels :value="0">
              <template v-for="[category, plans] in endUserPlan">
                <v-expansion-panel :key="category">
                  <v-expansion-panel-header class="subtitle-1">{{ category }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">契約名</th>
                            <th class="text-left">契約期間</th>
                            <th class="text-right">契約ポイント</th>
                            <th class="text-right">使用ポイント</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(plan) in plans">
                          <tr :key="plan.id">
                            <td>{{ plan.plan_mst.plan_name }}</td>
                            <td>{{ plan.start_contract_date | moment("YYYY/MM/DD") }} 〜 {{ plan.end_contract_date | moment("YYYY/MM/DD") }}</td>
                            <td class="text-right">{{ plan.max_point }}<span class="unit">Pt</span></td>
                            <td class="text-right">{{ plan.point_total }}<span class="unit">Pt</span></td>
                          </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
              </v-expansion-panels>
            </v-container>
          </v-tab-item>

          <!-- サービス一覧 -->
          <v-tab-item>
            <v-container>
              <v-card>
                <v-card-title>
                  {{endUserActiveContractPlan.plan_name}}
                </v-card-title>
                <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel v-for="[cat, services] in endUserActiveContractServices" :key="cat">
                    <v-expansion-panel-header class="subtitle-1">
                      {{ cat }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table hide-default-header hide-default-footer no-data-text="サービスは登録されていません">
                                <tbody>
                                  <tr v-for="(service, j) in services" :key="j">
                                    <td v-if="service.point_mst" class="pa-2 font-weight-bold" style="width: 30%;">
                                      {{ service.point_mst.service }}</td>
                                    <td v-else class="pl-4">名前なし</td>
                                    <td v-if="service.point_mst" style="white-space: pre-line; width: 60%" class="pa-2">
                                      <span v-text="service.point_mst.point_content" v-linkified></span>
                                    </td>
                                    <td v-else>-</td>
                                    <td v-if="endUserActiveContractPlan.contract_model == 'point'" class="text-right"><strong>{{ service.point }}</strong> {{ getPlanUnit(endUserActiveContractPlan.contract_model) }}</td>
                                    <td v-else class="text-right">{{ getPlanUnit(endUserActiveContractPlan.contract_model) }}</td>
                                  </tr>
                                </tbody>
                        </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-text style="white-space: pre-line;" v-if="endUserActiveContractPlan.plan_memo">
                  <span v-text="endUserActiveContractPlan.plan_memo" v-linkified></span>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>

          <!-- 会社情報 -->
          <v-tab-item>
            <v-container>
              <v-card>
                <v-card-title>{{company_info.end_company_name}}</v-card-title>
                <v-card-subtitle><span class="grey--text text--lighten-1" v-show="company_info.end_company_kana">{{company_info.end_company_kana}}</span></v-card-subtitle>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col col="12" md="12">
                      <h4>住所</h4>
                      <p>〒&nbsp;{{ company_info.zip_code }}&nbsp;{{ company_info.address }}</p>
                    </v-col>
                    <v-col cols="12" md="6" v-if="company_info.phone && company_info.phone !== 'undefined'">
                      <h4>電話番号</h4>
                      <p>
                        <v-icon size="22" left>mdi-deskphone</v-icon>{{ company_info.phone }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" v-if="company_info.fax && company_info.fax !== 'undefined'">
                        <h4>FAX番号</h4>
                        <p>
                          <v-icon size="22" left>mdi-fax</v-icon>{{ company_info.fax }}
                        </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>

          <!-- サポート担当・その他 -->
          <v-tab-item>
            <v-container>
              <v-card>
                <v-card-title>担当者</v-card-title>
                <v-divider />
                <v-card-text>
                  <!-- <v-container> -->
                    <v-row v-if="company_info.primary_contact_login">
                      <v-col cols="12">
                        <h4>サポート担当者</h4>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-list>
                            <v-list-item>
                              <v-list-item-avatar style="height: auto; min-width: auto; width: auto;">
                                <LoginAvatar :user="company_info.primary_contact_login" :size="40" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{ company_info.primary_contact_login.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ company_info.primary_contact_login.kana }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action-text class="ml-0">
                                  <v-tooltip top v-if="company_info.primary_contact_login.email">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon @click.stop="copyClipboard(company_info.primary_contact_login.email)"><v-icon small v-bind="attrs" v-on="on">mdi-email</v-icon></v-btn>
                                    </template>
                                    <span>{{company_info.primary_contact_login.email}}</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="company_info.primary_contact_login.phone">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon @click.stop="copyClipboard(company_info.primary_contact_login.phone)"><v-icon small v-bind="attrs" v-on="on">mdi-cellphone</v-icon></v-btn>
                                    </template>
                                    <span>{{company_info.primary_contact_login.phone}}</span>
                                  </v-tooltip>
                              </v-list-item-action-text>
                            </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <h4>サブ担当者</h4>
                        <v-row dense>
                          <v-col cols="12" sm="6" md="4" v-for="item of secondaryManagerLists" :key = item.id>
                            <v-list>
                            <v-list-item>
                              <v-list-item-avatar style="height: auto; min-width: auto; width: auto;">
                                <LoginAvatar :user="item" :size="40" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.kana }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action-text>
                                  <v-tooltip top v-if="item.email">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon @click.stop="copyClipboard(item.email)"><v-icon small v-bind="attrs" v-on="on">mdi-email</v-icon></v-btn>
                                    </template>
                                    <span>{{item.email}}</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="item.phone">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn small icon @click.stop="copyClipboard(item.phone)"><v-icon small v-bind="attrs" v-on="on">mdi-cellphone</v-icon></v-btn>
                                    </template>
                                    <span>{{item.phone}}</span>
                                  </v-tooltip>
                              </v-list-item-action-text>
                            </v-list-item>
                            </v-list>
                              <v-divider />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  <!-- </v-container> -->
                </v-card-text>
              </v-card>
              <!-- other info -->
              <v-card v-if="company_info.production_environment || company_info.test_environment">
                <v-card-title>その他の情報</v-card-title>
                <v-divider />
                <v-card-text>
                  <template v-if="company_info.production_environment">
                    <h4>本番サーバー</h4>
                    <pre v-linkified>{{company_info.production_environment}}</pre>
                  </template>
                  <template v-if="company_info.test_environment">
                    <h4>テストサーバー</h4>
                    <pre v-linkified>{{company_info.test_environment}}</pre>
                  </template>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs>
        <message-bar ref="message" />
      </div>
</template>

<script>
import axios from '@/plugins/axios'
import MessageBar from '../../components/MessageBar.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

const ACTIVE_CONTRACT_PLAN = '現在有効な契約情報'
const OLD_CONTRACT_PLAN = '過去の契約情報'
const FUTURE_CONTRACT_PLAN = '先の契約情報'

export default {
  components: { MessageBar, LoginAvatar },
  name: 'Contract',
  data: () => ({
    today: '',
    selectedTabs: 0,
    tabs: [
      {
        name: '契約情報',
        route: { name: 'client:user_show', params: { section: 'info' } },
        icon: 'mdi-file-sign'
      },
      {
        name: 'サービス一覧',
        route: { name: 'client:user_show', params: { section: 'service' } },
        icon: 'mdi-account'
      },
      {
        name: '会社情報',
        route: { name: 'client:user_show', params: { section: 'company' } },
        icon: 'mdi-domain'
      },
      {
        name: 'サポート担当・その他',
        route: { name: 'client:user_show', params: { section: 'support' } },
        icon: 'mdi-account'
      }
    ],
    endUserPlan: null,
    endUserActiveContractPlan: {},
    endUserActiveContractServices: null,
    company_info: {},
    tableHeaders: [
      { text: 'サービス名', value: 'title', cellClass: 'service-title' },
      { text: 'サービス説明', value: 'description' },
      { text: '消費', value: 'consumption', cellClass: 'service-consumption' }
    ],
    categories: {
      items: [
        { id: 1, title: 'EC管理' },
        { id: 2, title: 'コンテンツサポート' },
        { id: 3, title: '基本サポート' },
        { id: 0, title: '未分類' }
      ]
    },
    plan: {
      planType: 1,
      title: 'スーパープランA',
      services: [
        { categoryId: 1, title: 'CSVアップロード', description: '商品情報のCSVをアップロードします。', consumption: 20, used: 5 },
        { categoryId: 2, title: 'メールマガジン成（HTML）', description: 'HTMLメールでメールマガジンを作成します。（ライティング、デザイン込）', consumption: 10, used: 2 },
        { categoryId: 2, title: 'メールマガジン作成（テキスト）', description: 'テキストでメールマガジンを作成します。', consumption: 100, used: 0 }
      ],
      plan_memo: '超お得プランです',
      plan_admin_memo: '',
      create_at: null,
      update_at: null
    }
  }),
  computed: {
    secondaryManagerLists () {
      const contacts = []
      for (let i = 0; i < 25; i++) {
        const contact = this.company_info['secondary_contact_' + String(i).padStart('2', '0') + '_login']
        if (contact) contacts.push(contact)
      }
      return contacts
    }
  },
  methods: {
    getPlanUnit: function (typeId) {
      let unit = ''
      if (typeId === 'point') unit = 'pt'
      else if (typeId === 'unlimit') unit = '∞'
      return unit
    },
    getCategoryTitle (id) {
      return this.categories.items.find((v) => v.id === id).title
    },
    getTabsId: function (pageParam) {
      var tabsid
      switch (pageParam) {
        case 'info':
          tabsid = 0
          break
        case 'service':
          tabsid = 1
          break
        case 'company':
          tabsid = 2
          break
        case 'support':
          tabsid = 3
          break
      }
      return tabsid
    },
    updateRoute (route) {
      this.$router.push(route, () => {})
    },
    readDataFromAPI: function () {
      const endUserId = this.$store.getters.loginUserInfo.end_user.id
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${endUserId}`)
        .then(res => {
          this.company_info = res.data
          this.endUserPlanToCategory() // contract/info
          this.endUserPlanServiceToCategory() // contract/service
        })
        .catch(err => {
          console.log(err)
        })
    },
    endUserPlanToCategory: function () {
      const map = new Map()
      this.company_info.end_user_plan.forEach((va) => {
        var category = ACTIVE_CONTRACT_PLAN
        if (va.start_contract_date > this.today) {
          category = FUTURE_CONTRACT_PLAN
        } else if (va.end_contract_date < this.today) {
          category = OLD_CONTRACT_PLAN
        }
        var arr
        if (map.has(category)) {
          arr = map.get(category)
          arr.push(va)
        } else {
          arr = []
          arr.push(va)
        }
        arr.sort((a, b) => (a.start_contract_date > b.start_contract_date) ? 1 : ((b.start_contract_date > a.start_contract_date) ? -1 : 0))
        map.set(category, arr)
      })
      this.endUserPlan = new Map([...map.entries()].sort())
    },
    endUserPlanServiceToCategory: function () {
      if (this.endUserPlan.has(ACTIVE_CONTRACT_PLAN)) {
        this.endUserActiveContractPlan = this.endUserPlan.get(ACTIVE_CONTRACT_PLAN)[0].plan_mst
        const map = new Map()
        this.endUserActiveContractPlan.plan_mst_service.forEach((va) => {
          var category = ''
          if (va.point_mst?.point_mst_category) {
            category = va.point_mst.point_mst_category.name
          } else {
            category = '未分類'
          }

          var arr
          if (map.has(category)) {
            arr = map.get(category)
            arr.push(va)
          } else {
            arr = []
            arr.push(va)
          }
          arr.sort((a, b) => (a.point_mst.service > b.point_mst.service) ? 1 : ((b.point_mst.service > a.point_mst.service) ? -1 : 0))
          map.set(category, arr)
        })
        this.endUserActiveContractServices = new Map([...map.entries()].sort())
      }
    },
    todayDate: function () {
      var d = new Date()
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
      this.today = [year, month, day].join('-')
    },
    copyClipboard (str) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str)
        this.$refs.message.show(`${str}をクリップボードにコピーしました`)
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.section) {
      this.selectedTabs = this.getTabsId(to.params.section)
    }
    next()
  },
  mounted: function () {
    if (this.$route.params.section) {
      this.selectedTabs = this.getTabsId(this.$route.params.section)
    }
  },
  created: function () {
    this.todayDate()
    this.readDataFromAPI()
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">

.v-list-item__subtitle{
  white-space: pre-line;
}

::v-deep .v-data-table{

  .service-title{
    width: 30%!important;
  }

  .service-consumption{
    width: 20%;
    // text-align: right!important;
    // white-space: nowrap;
  }

}

.service-dialog{
  .v-card__text{
    height: 50vh;
  }
}

.edit-plan{
  display: none;
}

.v-expansion-panel--active{
  .edit-plan{
    display: inline-block;
  }
}

::v-deep .theme--light{
  .v-data-table{
    .v-row-group__header{
      background: transparent;
      &:first-child{
        td{
          padding-top: 0!important;
        }
      }
        &:hover{
          background: transparent!important;
        }
        td{
          border: none!important;
        }
      }
  }
}

main .v-card{
  margin-bottom: 20px;
}
</style>
